import { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SelectTable, { SelectColumnType } from 'components/Table/SelectTable';
import ConfirmButton from 'components/common/ConfirmButton';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import Swal from 'sweetalert2';
import { AxiosError } from 'axios';

export default function BatchEnrolment() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [students, setStudents] = useState<any[]>([]);
    const [batches, setBatches] = useState<any[]>([]);
    const [batchID, setBatchID] = useState(0);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    const batchName = () => {
        if (batches && batchID) {
            const batchFound = batches.find((item) => item.id === batchID);
            if (batchFound) {
                return batchFound.name;
            }
        }
        return '';
    };

    const StudentColumns: SelectColumnType[] = [
        {
            header: langString('name'),
            accessor: 'fullname'
        },
        {
            header: langString('phone'),
            accessor: 'phone'
        },
        {
            header: 'Add',
            accessor: 'action',
            content: (item: any) => (
                <>
                    <ConfirmButton
                        icon={<AddCircleIcon />}
                        title={langString('add')}
                        subTitle={`Add ${item.fullname} to batch ${batchName()}`}
                        confirmed={() => addToBatch(item.id)}
                    />
                </>
            )
        }
    ];

    const addToBatch = async (id: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'add_student_in_batch'),
                makeFormData([
                    { key: 'batch_id', value: batchID },
                    { key: 'student_user_id', value: id }
                ])
            );
            if (response.data.success) {
                refreshList();
                setSnackbar({ open: true, message: langString('studentaddedsuccessfully'), alert: 'success' });
                Swal.fire('Done', `${langString('studentaddedsuccessfully')}`, 'success');
            } else {
                setSnackbar({ open: true, message: response.data?.msg, alert: 'error' });
                Swal.fire('Error!', `${response.data?.msg}`, 'error');
            }
            setLoading(false);
        } catch (e) {
            const error = e as AxiosError;
            console.log(error);
            setLoading(false);
            setSnackbar({ open: true, message: error.response?.data.msg });
            Swal.fire('Error!', `${error.response?.data.msg}`, 'error');
        }
    };

    const bulkAddToBatch = async () => {
        if (selectedStudents && selectedStudents.length) {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'add_students'),
                    makeFormData([
                        { key: 'batch_id', value: batchID },
                        { key: 'students', value: selectedStudents }
                    ])
                );
                if (response.data.success) {
                    setSnackbar({ open: true, message: langString('studentaddedsuccessfully'), alert: 'success' });
                    Swal.fire('Done', `${langString('studentaddedsuccessfully')}`, 'success');
                    refreshList();
                } else {
                    setSnackbar({ open: true, message: response.data?.msg });
                    Swal.fire('Error!', `${response.data?.msg}`, 'error');
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
                const error = e as AxiosError;
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
                Swal.fire('Error!', `${error.response?.data.msg}`, 'error');
            }
        } else {
            setSnackbar({ open: true, message: langString('nostudentselected') });
            Swal.fire('Error!', `${langString('nostudentselected')}`, 'error');
        }
    };

    const selectAllStudents = (value: boolean) => {
        if (value) {
            setCheckAll(true);
            if (students) {
                const allStudents: number[] = [];
                students.forEach((st) => allStudents.push(st.id));
                setSelectedStudents(allStudents);
            }
        } else {
            setCheckAll(false);
            setSelectedStudents([]);
        }
    };

    const selectSingleStudent = (studentID: number) => {
        const allSelectedStudents = [...selectedStudents];
        if (allSelectedStudents.includes(studentID)) {
            const reduced = allSelectedStudents.filter((item) => item !== studentID);
            setSelectedStudents(reduced);
        } else {
            allSelectedStudents.push(studentID);
            setSelectedStudents(allSelectedStudents);
        }
    };

    useEffect(() => {
        if (course && course.batches) {
            setBatches(course.batches);
            if (batches) {
                setBatchID(batches[0]?.id);
            } else {
                setSnackbar({ open: true, message: langString('nobatchavailable') });
            }
        }
    }, [course, batches]);

    const refreshList = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'users_except_batch'),
                makeFormData([
                    { key: 'course_id', value: course?.id },
                    { key: 'batch_id', value: batchID },
                    { key: 'search', value: search },
                    { key: 'rows_per_page', value: rowsPerPage },
                    { key: 'page_no', value: page }
                ])
            );
            if (response.data.success) {
                if (response.data?.response?.data) {
                    setStudents(response.data.response?.data);
                    setCount(response.data.response?.totalCount);
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'users_except_batch'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'batch_id', value: batchID },
                        { key: 'search', value: search },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response?.data) {
                        setStudents(response.data.response?.data);
                        setCount(response.data.response?.totalCount);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course && batchID) {
            init();
        }
    }, [course, batchID, search, rowsPerPage, page]);

    return (
        <Wrapper
            title={langString('enrolment')}
            icon={<GroupAddIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
            addLink="/add-student"
        >
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth sx={{ paddingRight: 1 }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        id="outlined-search"
                        value={search}
                        name="search"
                        size="small"
                        onChange={(event: any) => setSearch(event.target.value)}
                        label={langString('search')}
                        placeholder={langString('search')}
                    />
                </Grid>
                <Grid item sm={4} sx={{ textAlign: 'right' }}>
                    <Button color="primary" variant="contained" onClick={() => bulkAddToBatch()}>
                        {langString('addselectedstudents')}
                    </Button>
                </Grid>
            </Grid>
            {!loading && (
                <Grid container>
                    <Grid item sm={12}>
                        <SelectTable
                            idField="id"
                            rows={students}
                            columns={StudentColumns}
                            showSL
                            count={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={(value: number) => setPage(value)}
                            setRowsPerPage={(value: number) => setRowsPerPage(value)}
                            selectedFields={selectedStudents}
                            checkAll={checkAll}
                            changeCheckAll={(value: boolean) => selectAllStudents(value)}
                            checkUpdate={(id: number) => selectSingleStudent(id)}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            )}
        </Wrapper>
    );
}
