import { useState, useEffect } from 'react';
import { Box, FormControl, Grid, Typography, InputLabel, Select, MenuItem } from '@mui/material';
import { BarOptions, BarSeries } from 'utils/ChartTypes';
import ReactApexChart from 'react-apexcharts';
import axiosServices from 'utils/axiosServices';
import { makeFormData, marksColor, requestUrl } from 'utils/Helpers';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import langString from 'utils/langString';

type DashboardChartAllQuizPropsType = {
    batches: any[];
};
export default function DashboardChartAllQuiz({ batches }: DashboardChartAllQuizPropsType) {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [options, setOptions] = useState<BarOptions | null>(null);
    const [series, setSeries] = useState<BarSeries[]>([]);
    const [batchID, setBatchID] = useState(0);

    useEffect(() => {
        if (batches && batches.length) {
            setBatchID(batches[0].id);
        }
    }, [batches]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'quizes'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'batch_id', value: batchID }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response && response.data?.response.length) {
                        const labels = response.data.response.map((item: any) => item.details?.name);
                        const dataList = response.data.response.map((item: any) => item.mark?.averageMarkPercentage);
                        const barColors: string[] = dataList.map((mark: string) => marksColor(parseFloat(mark)));
                        setSeries([{ name: '%', data: dataList }]);
                        setOptions({
                            chart: {
                                type: 'bar',
                                height: 350,
                                toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true,
                                        customIcons: []
                                    },
                                    export: {
                                        csv: {
                                            filename: 'AllQuizMark',
                                            columnDelimiter: ',',
                                            headerCategory: 'Quiz',
                                            headerValue: 'value',
                                            dateFormatter(timestamp: any) {
                                                return new Date(timestamp).toDateString();
                                            }
                                        },
                                        svg: {
                                            filename: 'AllQuizMark'
                                        },
                                        png: {
                                            filename: 'AllQuizMark'
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }
                            },
                            colors: barColors,
                            plotOptions: {
                                bar: {
                                    distributed: true,
                                    borderRadius: 4,
                                    horizontal: false
                                }
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                categories: labels
                            }
                        });
                    } else {
                        setOptions(null);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course && batchID) {
            init();
        }
    }, [batchID, course]);

    return (
        <Box sx={{ marginY: 3, borderBottom: '1px solid #DDD', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Box sx={{ padding: 1.5, border: '1px solid #e67e22', borderRadius: '10px', textAlign: 'center' }}>
                        <Typography variant="h5" sx={{ color: '#e67e22' }}>
                            All Quiz Comparison
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={8}>
                    <FormControl sx={{ paddingRight: 1, float: 'right', minWidth: '150px' }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginY: 1 }}>
                <Grid item sm={12}>
                    {options && series ? (
                        <ReactApexChart options={options} series={series} type="bar" height={350} />
                    ) : (
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {langString('nodatafound')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
