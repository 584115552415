import { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import Wrapper from 'components/Card/Wrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import SearchBox from 'components/common/SearchBox';
import AddBatch from './Manage/Add';
import EditBatch from './Manage/Edit';
import ConfirmButton from 'components/common/ConfirmButton';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import HailIcon from '@mui/icons-material/Hail';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { COURSE_SELECT } from 'store/actions';

export default function BatchList() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [batches, setBatches] = useState<any[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const navigate = useNavigate();
    const dispatchCourseInfo = useDispatch();

    const batchName = (batchID: number) => {
        if (batches) {
            const batchFound = batches.find((item) => item.id === batchID);
            if (batchFound) {
                return batchFound.name;
            }
        }
        return '';
    };

    const BatchColumns: ColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name',
            content: (item: any) => <Typography>{item.name}</Typography>
        },
        {
            header: langString('schedule'),
            accessor: 'schedules',
            content: (item: any) => item.schedules && item.schedules.map((sch: string, key: number) => (key !== 0 ? `, ${sch}` : sch))
        },
        {
            header: langString('studentcapacity'),
            accessor: 'max_students'
        },
        {
            header: langString('studentenrolled'),
            accessor: 'enrolled_students'
        },
        // {
        //     header: langString('enrolmentkey'),
        //     accessor: 'enrolmentkey'
        // },
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <>
                    <IconButton onClick={() => navigate(`/batch-students/${item.id}`)}>
                        <Tooltip title={langString('view')}>
                            <VisibilityIcon />
                        </Tooltip>
                    </IconButton>
                    <ConfirmButton
                        color="error"
                        icon={<DeleteForeverIcon />}
                        subTitle={`Delete Batch ${batchName(item.id)}`}
                        confirmed={() => deleteBatch(item.id)}
                    />
                    <EditBatch
                        batchID={item.id}
                        batchName={item.name}
                        batchKey={item.enrolmentkey}
                        batchCapacity={item.max_students}
                        batchSchedules={item.schedules?.length ? item.schedules : ['Sunday']}
                        refreshList={() => refreshList()}
                        setSnackbar={(value: SnackBarType) => setSnackbar(value)}
                    />
                </>
            )
        }
    ];

    const refreshList = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'get_batches'),
                makeFormData([{ key: 'course_id', value: course?.id }])
            );
            if (response.data.success) {
                if (response.data?.response) {
                    setBatches(response.data.response);
                    dispatchCourseInfo({
                        type: COURSE_SELECT,
                        course: {
                            id: course.id,
                            name: course.name,
                            categoryID: course.categoryID,
                            categoryName: course.categoryName,
                            batches: response.data?.response || []
                        }
                    });
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    const deleteBatch = async (id: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'delete_batch'),
                makeFormData([{ key: 'batch_id', value: id }])
            );
            if (response.data.success) {
                if (response.data?.response) {
                    refreshList();
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    useEffect(() => {
        if (course && course.batches) {
            setBatches(course.batches);
            if (!batches) {
                setSnackbar({ open: true, message: langString('nobatchavailable') });
            }
        }
    }, [course, batches]);

    return (
        <Wrapper
            title={langString('batch')}
            icon={<GroupIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item sm={6}>
                    <SearchBox
                        placeholder={`${langString('search')} ${langString('batch')}`}
                        properties={['name']}
                        itemList={batches}
                        setItemList={(values) => setBatches(values)}
                        refreshList={refreshList}
                    />
                </Grid>
                <Grid item sm={6} sx={{ textAlign: 'right' }}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button variant="contained" component={RouterLink} to="/attendence" startIcon={<HailIcon />} color="success">
                                {langString('attendence')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/attendence-report"
                                startIcon={<LocalLibraryIcon />}
                                color="success"
                            >
                                {langString('attendencereport')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <AddBatch refreshList={() => refreshList()} setSnackbar={(value: SnackBarType) => setSnackbar(value)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!loading && (
                <Grid container>
                    <Grid item sm={12}>
                        <BasicDataTable rows={batches} columns={BatchColumns} showSL />
                    </Grid>
                </Grid>
            )}
        </Wrapper>
    );
}
