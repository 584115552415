import { Typography, Box, Card, CardMedia, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COURSE_SELECT } from 'store/actions';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl, setCourseInfo } from 'utils/Helpers';
import { useEffect, useState } from 'react';
import { DefaultRootStateProps } from 'types';
// import langString from 'utils/langString';

type CourseItemPropsType = {
    id: number;
    name: string;
    image: string;
    categoryID: number;
    categoryName: string;
    selected?: boolean;
};
export default function CourseItem({ id, name, categoryID, categoryName, image, selected }: CourseItemPropsType) {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const navigate = useNavigate();
    const [topics, setTopics] = useState<string[]>([]);
    const [difficulties, setDifficulties] = useState<string[]>([]);

    const dispatchCourseInfo = useDispatch();

    const getTopics = async () => {
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'get_topics'),
                makeFormData([{ key: 'course_id', value: course?.id || 0 }])
            );
            if (response.status === 200 && response.data.success) {
                if (response.data?.response) {
                    setTopics(response.data.response);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
    const getDifficulties = async () => {
        try {
            const response = await axiosServices.post(requestUrl('ace_exam', 'get_dificulties'));
            if (response.status === 200 && response.data.success) {
                if (response.data?.response) {
                    setDifficulties(response.data.response);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getTopics();
        getDifficulties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToQuestionBank = async () => {
        if (id && categoryID) {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batches'),
                    makeFormData([{ key: 'course_id', value: id }])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        dispatchCourseInfo({
                            type: COURSE_SELECT,
                            course: {
                                id,
                                name,
                                categoryID,
                                categoryName,
                                topics: topics || [],
                                difficulties: difficulties || [],
                                batches: response.data?.response || []
                            }
                        });
                        setCourseInfo(id.toString(), name.toString(), categoryID.toString(), categoryName.toString());
                        navigate('/question-bank');
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        // <Box
        //     sx={{
        //         padding: 1,
        //         border: `1px solid ${selected ? '#273c75' : '#EEE'}`,
        //         margin: 1,
        //         textAlign: 'center',
        //         cursor: 'pointer',
        //         minHeight: '150px',
        //         minWidth: '150px'
        //     }}
        //     onClick={() => goToQuestionBank()}
        // >
        //     <img src={image} alt="coursepic" style={{ width: '100px', minHeight: '100px' }} />
        //     <Typography variant="h3">{name}</Typography>
        // </Box>
        <Card
            variant="outlined"
            sx={{
                padding: 1,
                cursor: 'pointer',
                background: selected ? '#ede7f6' : '#FFF',
                boxShadow: 1
            }}
            onClick={() => goToQuestionBank()}
        >
            <Box
                sx={{
                    background: '#EEE',
                    width: '40%',
                    height: '30%',
                    float: 'left',
                    borderRadius: '15px',
                    padding: '7px',
                    boxShadow: 2,
                    minWidth: { xs: '100px' }
                }}
            >
                {/* {courseItem.coursepic && <img src={courseItem.coursepic} alt="coursepic" style={{ width: '100%' }} />} */}
                <CardMedia
                    component="img"
                    sx={{ width: '100%', height: 'auto', minHeight: { xl: '100px' } }}
                    image={image}
                    alt="coursepic"
                />
            </Box>
            <Box sx={{ width: '60%', float: 'left' }}>
                <Grid container justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ margin: '10px' }}>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}
