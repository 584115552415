import { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { checkDecimal, checkNullInfo, makeFormData, requestUrl } from 'utils/Helpers';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { useNavigate } from 'react-router-dom';

export default function QuizReports() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [quizes, setQuizes] = useState<any[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const navigate = useNavigate();

    const QuizColumns: ColumnType[] = [
        {
            header: langString('quizname'),
            accessor: 'quizname',
            content: (item: any) => <Typography>{checkNullInfo(item?.details?.name)}</Typography>
        },
        {
            header: langString('mark'),
            accessor: 'totalmark',
            content: (item: any) => <Typography>{checkDecimal(item?.mark?.totalmark)}</Typography>
        },
        {
            header: langString('highestmark'),
            accessor: 'highestMark',
            content: (item: any) => <Typography>{checkDecimal(item?.mark?.highest)}</Typography>
        },
        {
            header: langString('averagemark'),
            accessor: 'averageMark',
            content: (item: any) => <Typography>{checkDecimal(item?.mark?.average)}</Typography>
        },
        {
            header: langString('participants'),
            accessor: 'participants',
            content: (item: any) => <Typography>{checkDecimal(item?.details?.participants)}</Typography>
        },
        {
            header: 'View',
            accessor: 'view',
            content: (item: any) => (
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => navigate(`/quiz-participants/${item?.details?.quizid}`)}
                >
                    View
                </Button>
            )
        }
    ];

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'quizes'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setQuizes(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    }, [course]);

    return (
        <Wrapper
            title={langString('coursereports')}
            icon={<AssessmentIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container>
                <Grid item sm={12}>
                    <BasicDataTable rows={quizes} columns={QuizColumns} showSL />
                </Grid>
            </Grid>
        </Wrapper>
    );
}
