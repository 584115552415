/* eslint-disable no-plusplus */
import defaultImage from 'assets/images/placeholder/default.png';
import { BASEURL, SERVICE_NAME } from './Constants';
import langString from './langString';
import _ from 'lodash';
import { Dayjs } from 'dayjs';

export const fullFilePath = (picPath: string) => {
    if (!picPath) {
        return defaultImage;
    }
    if (picPath.includes('http')) {
        return picPath;
    }
    return `${BASEURL}${picPath
        ?.split('/')
        .filter((v) => v !== '')
        .join('/')}`;
};

export const checkNullInfo = (info: string | number | null | undefined) => {
    if (info || typeof info == 'number') {
        return info;
    }
    return langString('---');
};

export const requestUrl = (feature: string, method: string) => `${feature}/${SERVICE_NAME}=${method}`;

export const groupByKey = (data: any[], objectKey: string) => {
    const groupObject = data.reduce((result, currentValue) => {
        (result[currentValue[objectKey]] = result[currentValue[objectKey]] || []).push(currentValue);
        return result;
    }, {});
    const keyValues: any[] = [];
    Object.entries(groupObject).forEach((entry) => {
        const [key, value] = entry;
        keyValues.push({ key, value });
    });
    return keyValues;
};

export type FormDataType = {
    key: string;
    value: string | number | string[] | number[] | Dayjs;
};
export const makeFormData = (data: FormDataType[]) => {
    const formData = new FormData();
    data.forEach((item: any) => {
        if (Array.isArray(item.value)) {
            const arrayKey = `${item.key}[]`;
            item.value.forEach((el: any) => formData.append(arrayKey, el.toString()));
        } else {
            formData.append(item.key, item.value?.toString());
        }
    });
    return formData;
};

export const removeCourseInfo = () => {
    localStorage.removeItem('courseID');
    localStorage.removeItem('courseName');
    localStorage.removeItem('categoryID');
    localStorage.removeItem('categoryName');
};

export const setCourseInfo = (courseID: string, courseName: string, categoryID?: string, categoryName?: string) => {
    localStorage.setItem('courseID', courseID);
    localStorage.setItem('courseName', courseName);
    localStorage.setItem('categoryID', categoryID || '');
    localStorage.setItem('categoryName', categoryName || '');
};

export const moveInArray = (arr: any[], from: number, to: number) => {
    // Delete the item from it's current position
    const item = arr.splice(from, 1);
    // Move the item to its new position
    arr.splice(to, 0, item[0]);
    return arr;
};

export function checkDecimal(inputValue: any) {
    const decimal = /^[-+]?[0-9]+\.[0-9]+$/;
    if (inputValue && inputValue.length && inputValue.match(decimal)) {
        return parseFloat(inputValue).toFixed(2);
    }
    if (inputValue) {
        return inputValue;
    }
    return 0;
}

export const randomColor = () => {
    const colorCode = Math.floor(Math.random() * 16777215).toString(16);
    return `#${colorCode}`;
};

export const chipify = (value: string) =>
    `<span style="display: block; padding: 5px; text-align: center; border: 1px solid #535c68; margin-left: 7px; margin-top: 3px; border-radius: 5px; word-wrap: normal;">${value.replace(
        ' ',
        ''
    )}</span>`;

export const marksColor = (marks: number) => {
    if (marks >= 0 && marks <= 20) {
        return '#e74c3c';
    }
    if (marks >= 21 && marks <= 40) {
        return '#e67e22';
    }
    if (marks >= 41 && marks <= 60) {
        return '#f1c40f';
    }
    if (marks >= 61 && marks <= 80) {
        return '#3498db';
    }
    if (marks >= 81 && marks <= 100) {
        return '#1abc9c';
    }
    if (marks > 100) {
        return '#ace368';
    }

    return '#DDD';
};

export const sortedDataList = (dataList: number[], sortBy: string) => {
    if (sortBy === 'asc') {
        return dataList.sort((a, b) => a - b);
    }
    if (sortBy === 'desc') {
        return dataList.sort((a, b) => a - b).reverse();
    }
    return dataList;
};

export const sortedPairedDataList = (dataList: any[], sortBy: string | boolean) => {
    if (sortBy === 'asc' || sortBy === 'desc') {
        return _.orderBy(dataList, ['y'], [sortBy]);
    }
    if (sortBy === 'alp') {
        return _.sortBy(dataList, (data: any) => data.x.toLowerCase());
    }
    return dataList;
};
export const sortedLabelList = (labelList: string[]) => labelList.sort();

export const barChartData = (data: [], xaxisName: string, yaxisName: string) => {
    const parsedData: any = [];

    data.forEach((value: any) => {
        const parsedValue = {
            x: value[xaxisName],
            y: parseFloat(value[yaxisName])
        };
        parsedData.push(parsedValue);
    });

    return parsedData;
};

export const barChartData_1 = (xdata: [], ydata: []) => {
    const parsedData: any = [];

    for (let i = 0; i < xdata.length; i++) {
        const parsedValue = {
            x: xdata[i],
            y: ydata[i] === null ? 0.0 : parseFloat(ydata[i])
        };
        parsedData.push(parsedValue);
    }
    return parsedData;
};

export const answerOption = (key: number) => {
    const options = ['A', 'B', 'C', 'D', 'E', 'F'];
    return options[key];
};

export const getMedianDifficulty = (questions: any[]) => {
    const diffTags: any[] = [];
    // eslint-disable-next-line array-callback-return
    questions.map((question: any) => {
        const diffTag = _.find(question.tags, (tag: any) => tag.name === 'easy' || tag.name === 'medium' || tag.name === 'hard');
        if (diffTag) {
            diffTags.push(diffTag);
        }
    });
    const tagArray = _.map(diffTags, 'name');
    const result = _.head(_(tagArray).countBy().entries().maxBy(_.last));
    const mostCommonTag = _.find(diffTags, (tag: any) => tag.name === result);
    return mostCommonTag;
};

export const getAttendenceStatusColor = (item: any) => {
    if (item.totalAbsent < 1) {
        return 'success';
    }
    if (item.totalAbsent >= 1 && item.totalAbsent <= 3) {
        return 'warning';
    }
    return 'error';
};
export const func1 = (text: string, limit: number) => {
    const stack = [];
    let i = 0;
    for (i = 1; i < text.length; i++) {
        if (i >= limit && stack.length === 0) break;
        if (text[i - 1] === '\\' && text[i] === '(') {
            stack.push(i);
        } else if (text[i - 1] === '\\' && text[i] === ')') {
            if (stack.length > 0) {
                stack.pop();
            }
        }
    }
    return `${text.substring(0, i)} ...`;
};

export const tagColor = (topics: any, tagId: any) => {
    if (topics.includes(tagId)) {
        return 'error';
    }
    return 'info';
};
